import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_picker = _resolveComponent("van-picker");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createBlock(_component_van_popup, {
    show: $options.showPicker,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $options.showPicker = $event),
    round: "",
    position: "bottom"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_picker, {
      ref: "pickers",
      columns: _ctx.addressList,
      "columns-field-names": $data.customFieldName,
      onCancel: $options.cancel,
      onConfirm: $options.onConfirm
    }, null, 8, ["columns", "columns-field-names", "onCancel", "onConfirm"])]),
    _: 1
  }, 8, ["show"]);
}