import { mapState } from "vuex";
export default {
  props: ['show_picker', 'picker_info'],
  computed: {
    ...mapState({
      addressList: state => state.address.addressList
    }),
    showPicker() {
      return this.show_picker;
    }
  },
  watch: {
    'showPicker'(val) {
      if (val && this.picker_info && JSON.stringify(this.picker_info) != '{}') {
        this.$nextTick(() => {
          this.$refs.pickers.setColumnValue(0, this.picker_info.province);
          this.$refs.pickers.setColumnValue(1, this.picker_info.city);
          this.$refs.pickers.setColumnValue(2, this.picker_info.county);
        });
      }
    }
  },
  data() {
    return {
      customFieldName: {
        text: 'name',
        children: 'list'
      },
      pickerInfo: {
        province: '',
        city: '',
        county: '',
        address_obj: ''
      }
    };
  },
  methods: {
    onConfirm(value) {
      this.pickerInfo.province = value[0].name;
      this.pickerInfo.city = value[1].name;
      this.pickerInfo.county = value[2].name;
      this.pickerInfo.address_obj = this.pickerInfo.province + '/' + this.pickerInfo.city + '/' + this.pickerInfo.county;
      this.$emit('pickerInfo', this.pickerInfo);
      this.$emit('update:show_picker', false);
    },
    cancel() {
      this.$emit('update:show_picker', false);
    }
  }
};