export default {
  props: ['show', 'address_info'],
  computed: {
    showInfo() {
      return this.show;
    }
  },
  methods: {
    closePicker() {
      this.$emit('update:show', false);
    }
  }
};