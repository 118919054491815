import { throttle } from "@/utils/tools";
import { addHashAddress, updateHashAddress } from "@/api/index";
import CompPicker from './address_picker.vue';
import { mdToast } from '../../../utils/tools';
import { showFailToast, showSuccessToast } from "vant";
export default {
  props: {
    show_edit: {
      type: Boolean,
      default: false
    },
    stock_hash: {
      type: String
    },
    relation_id: {
      type: String
    },
    address_type: {
      // add: 新增  edit: 编辑 disabled: 不可编辑
      type: String,
      default: 'add'
    },
    address_info: {
      type: Object
    },
    prize_activity_id: {
      type: String
    },
    fill_end_time: {
      type: String
    },
    describe: {
      type: String
    }
  },
  components: {
    CompPicker
  },
  computed: {
    showEdit() {
      return this.show_edit;
    },
    cannotEdit() {
      return this.address_type == 'disabled';
    }
  },
  data() {
    return {
      showPicker: false,
      address_name: '',
      address_mobile: '',
      pickerInfo: {
        province: '',
        city: '',
        county: '',
        address_obj: ''
      },
      address: '',
      themeVars: {
        fieldPlaceholderTextColor: '#666',
        fieldInputDisabledTextColor: 'rgba(34,34,34,0.6)'
      }
    };
  },
  methods: {
    open() {
      if ((this.address_type == 'edit' || this.address_type == 'disabled') && this.address_info && JSON.stringify(this.address_info) != '{}') {
        this.pickerInfo.province = this.address_info.province;
        this.pickerInfo.city = this.address_info.city;
        this.pickerInfo.county = this.address_info.county;
        this.pickerInfo.address_obj = this.address_info.province + '/' + this.address_info.city + '/' + this.address_info.county;
        this.address_name = this.address_info.address_name;
        this.address_mobile = this.address_info.address_mobile;
        this.address = this.address_info.address;
      }
    },
    addAddress: throttle(async function () {
      if (this.address_type == 'disabled') return;
      const that = this;
      if (!that.address_name) {
        mdToast({
          type: 'waning',
          message: '请输入收件人'
        });
        return;
      }
      if (!that.address_mobile) {
        mdToast({
          type: 'waning',
          message: '请输入中国大陆手机号码'
        });
        return;
      }
      if (!that.address) {
        mdToast({
          type: 'waning',
          message: '请输入详细地址'
        });
        return;
      }
      if (!that.pickerInfo.province || !that.pickerInfo.city || !that.pickerInfo.county) {
        mdToast({
          type: 'waning',
          message: '请选择省/市/区'
        });
        return;
      }
      let obj = {
        stock_hash: that.stock_hash,
        relation_id: that.relation_id,
        address_name: that.address_name,
        address_mobile: that.address_mobile,
        province: that.pickerInfo.province,
        city: that.pickerInfo.city,
        county: that.pickerInfo.county,
        address: that.address
      };
      if (this.address_type == 'edit') {
        obj.prize_activity_id = this.prize_activity_id;
        let {
          status,
          data,
          message
        } = await updateHashAddress(obj);
        if (status == 0) {
          if (data) {
            showSuccessToast('修改成功');
            this.closePickerEdit();
          } else {
            showFailToast(message);
          }
        } else {
          showFailToast(message);
        }
      } else {
        let {
          status,
          data,
          message
        } = await addHashAddress(obj);
        if (status == 0) {
          if (data) {
            showSuccessToast('保存成功');
            this.closePickerEdit();
          } else {
            showFailToast(message);
          }
        } else {
          showFailToast(message);
        }
      }
      this.$emit('getMerger');
    }, 2000),
    closePickerEdit() {
      this.resetAddress();
      this.$emit('update:show_edit', false);
    },
    resetAddress() {
      this.address_name = "";
      this.address_mobile = "";
      this.address = "";
      this.pickerInfo = {
        province: "",
        city: "",
        county: "",
        address_obj: ""
      };
    },
    getPickerInfo(obj) {
      this.pickerInfo = obj;
    },
    openPicker() {
      if (this.address_type != 'disabled') {
        this.showPicker = true;
      }
    }
  }
};